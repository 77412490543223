/**
 * @description Singleton para gerar cores em sequencia, usada principalmente nos items do select.
 */
class ColorGenerator {
  private colors: string[];
  private counter: number;
  constructor() {
    this.counter = 0;
    this.colors = [];
    this.initColors();
  }

  /**
   * @description Inicia com as cores padrões retiradas da whitelabel
   * @private
   */
  private initColors() {
    this.colors = ["primary", "secondary", "quaternary"];
  }

  get shouldResetCounter(): boolean {
    return this.counter === this.colors.length;
  }

  /**
   * @description Recupera uma cor baseado no counter
   */
  colorFromArray(): string {
    if (this.shouldResetCounter) {
      this.counter = 0;
      this.colors = [this.colors[1], this.colors[2], this.colors[0]];
    }
    const color = this.colors[this.counter];
    this.counter++;
    return color;
  }
}

export default new ColorGenerator();
